import React from 'react';
import logo from '../img/logo-h-inverse.svg';

class Navbar extends React.Component {
  render() {
    return <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid px-5">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="DraftForge" height="40" style={{marginTop: '-5px'}} />
        </a>
      </div>
    </nav>;
  }
}

export default Navbar;
