import React from 'react';
import logo from '../img/logo-v-grey.svg';
import navlogo from '../img/logo-h-inverse.svg';
import Modal from "react-bootstrap/Modal";
import {
  Navigate,
  Link
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {createObject, deleteObject, listObjects} from '../tools/data';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {modal: false, filter: ''}
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.delete = this.delete.bind(this)
    this.search = this.search.bind(this)
  }

  search(e) {
    this.setState({filter: e.target.value})
  }

  delete(id) {
    deleteObject("doc#" + id);
    this.forceUpdate();
  }

  closeModal() {
    this.setState({modal: false})
  }

  openModal(name) {
    return () => {
      this.setState({modal: name})
    }
  }

  render() {
    const docs = listObjects('doc#');

    return <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid px-5">
          <a className="navbar-brand" href="/">
            <img src={navlogo} alt="DraftForge" height="40" style={{marginTop: '-5px'}} />
          </a>
          <div className="input-group search">
            <span className="input-group-text"><i className="fa fa-search" /></span>
            <input onChange={this.search} className="form-control me-sm-2" type="text" placeholder="Search" />
          </div>
          <button className="btn btn-secondary" onClick={() => this.setState({modal: 'provisional-patent'})} type="submit"><i className="fa fa-plus" />&nbsp; New Document</button>
        </div>
      </nav>
      <div className="container-fluid p-5 mb-5 jumbotron text-center">
          <img src={logo} height="150px" alt="DraftForge" />
          <p className="mt-5 mb-2 fs-4 text-white">DraftForge is a tool for drafting patents with industrial efficiency.<br/>Get started with an intelligently-generated draft in just 5 minutes.</p>
      </div>
      <div className="container-fluid px-5">
        <h4 className="mb-3">My Documents</h4>
        <DocumentTable docs={docs} delete={this.delete} filter={this.state.filter} />
      </div>
      <NewProvisionalPatent show={this.state.modal === "provisional-patent"} hide={this.closeModal} />
    </>;
  }
}

class DocumentTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {modal: false}
    this.closeModal = this.closeModal.bind(this)
    this.delete = this.delete.bind(this)
  }

  closeModal() {
    this.setState({modal: false})
  }

  delete(id) {
    return () => {
      this.props.delete(id)
      this.closeModal()
    }
  }

  render() {
    const name = (type) => {
      if (type === 'provisional-patent') return "Provisional Patent";
      else return "Unknown";
    }
    const panel = (row) => {
      return <>
        <Link to={"/doc/" + row.id} className="btn btn-secondary btn-sm me-1"><i className="fa fa-edit" /></Link>
        <button onClick={() => this.setState({row: row, modal: "delete"})} className="btn btn-secondary btn-sm"><i className="fa fa-trash" /></button>
      </>;
    }
    const columns = [
      { sortable: true, name: 'Type', selector: row => row.type, format: row => name(row.type) },
      { sortable: true, name: 'Title', selector: row => row.title  },
      { sortable: true, name: 'Inventor', selector: row => row.author },
      { sortable: true, name: 'Created', selector: row => row.created, format: row => moment(row.created).fromNow() },
      { sortable: true, name: 'Modified', selector: row => row.edited, format: row => moment(row.edited).fromNow() },
      { sortable: true, name: 'Actions', cell: row => panel(row) },
    ];
    if (this.state.redirect) return <Navigate to={"/doc/" + this.state.redirect} />;
    if (this.props.docs.length === 0) {
      return <div className="zero-state">
        <p>There are no documents here yet. Get started by creating a document.</p>
      </div>
    }
    return (<>
      <DataTable
        columns={columns}
        data={this.props.filter ? this.props.docs.filter(doc => Object.values(doc).some(attr => attr.toString().includes(this.props.filter))) : this.props.docs}
        highlightOnHover
        pointerOnHover
        onRowClicked={(row) => this.setState({redirect: row.id})}
      />
      <Modal show={this.state.modal === "delete"} onHide={this.closeModal}>
        <div className="modal-header">
          <h5 className="modal-title">Delete Document?</h5>
          <button onClick={this.closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        {this.state.modal === "delete" && <>
          <div className="modal-body py-0">
            <p>Are you sure you want to permanently delete this document?</p>
            <p><b>{this.state.row.title}</b></p>
            <ul>
              <li>{this.state.row.embodiments.length} Embodiments</li>
              <li>Created {moment(this.state.row.created).fromNow()}</li>
              <li>Edited {moment(this.state.row.edited).fromNow()}</li>
            </ul>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={this.delete(this.state.row.id)}>Delete</button>
            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
          </div>
        </>}
      </Modal>
    </>)
  }
}

class NewProvisionalPatent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      field: '',
      inventor: ''
    }
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onInventorChange = this.onInventorChange.bind(this);
    this.create = this.create.bind(this);
  }

  onTitleChange(e) {
    this.setState({title: e.target.value})
  }

  onFieldChange(e) {
    this.setState({field: e.target.value})
  }

  onInventorChange(e) {
    this.setState({inventor: e.target.value})
  }

  create(e) {
    e.preventDefault()
    if (this.state.title && this.state.inventor && this.state.field) {
      const id = uuidv4();
      const doc = {
        id: id,
        type: 'provisional-patent',
        title: this.state.title,
        field: this.state.field,
        author: this.state.inventor,
        created: Date.now(),
        edited: Date.now(),
        embodiments: [],
        elements: [],
        dictionary: []
      }
      createObject('doc#' + id, doc)
      this.setState({id: id})
    }
  }

  render() {
    if (this.state.id) return <Navigate to={"/doc/" + this.state.id} />;
    return (
      <Modal show={this.props.show} onHide={this.props.hide} className="modal-wide">
        <div className="modal-header">
          <h5 className="modal-title">New Provisional Patent</h5>
          <button onClick={this.props.hide} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        <form onSubmit={this.create}>
          <div className="modal-body py-0">
            <div className="row">
              <div className="col-6 py-3">
                <div className="form-group">
                  <label htmlFor="provisional-patent-title" className="form-label">Title</label>
                  <input onChange={this.onTitleChange} type="text" className="form-control" id="provisional-patent-title" placeholder="eg. System and Method for Multi-Factor Authentication" defaultValue={this.state.title} />
                  <label htmlFor="provisional-patent-inventor" className="form-label mt-4">Inventor</label>
                  <input onChange={this.onInventorChange} type="text" className="form-control" id="provisional-patent-inventor" placeholder="eg. James Smith" defaultValue={this.state.inventor} />
                  <label htmlFor="provisional-patent-field" className="form-label mt-4">Field</label>
                  <input onChange={this.onFieldChange} type="text" className="form-control" id="provisional-patent-field" placeholder="eg. Information Security" defaultValue={this.state.field} />
                </div>
              </div>
              <div className="col-6 bg-light py-3">
                <p className="preview-text mt-0 mb-2">Preview</p>
                <div className="page-preview">
                  <h1 className="text-center"><b>{this.state.title ? this.state.title : <span className="text-muted">System and Method for Multi-Factor Authentication</span>}</b></h1>
                  <h1 className="text-center">{this.state.inventor ? this.state.inventor : <span className="text-muted">James Smith</span>}</h1>
                  <div className={this.state.field ? '' : 'text-muted'}>
                    <h1><b>Field</b></h1>
                    <p className="mb-0"><b>[0001]</b> This disclosure relates to the field of {this.state.field ? this.state.field.toLowerCase() : 'information security'}. More particularly, this disclosure relates to {this.state.title ? this.state.title.toLowerCase().replaceAll("system", "systems").replaceAll("method", "methods").replaceAll("process", "processes") : 'systems and methods for multi-factor authentication'}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary" disabled={!(this.state.title && this.state.inventor && this.state.field)}>Create</button>
            <button type="button" className="btn btn-secondary" onClick={this.props.hide}>Cancel</button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default Home;
