import profaneWords from './profane';
const docx = require("docx");
const boilerplate = require("./boilerplate");
const escapeRegExp = require('lodash.escaperegexp');

function forge(spec, addHeader, addPara, addClaim, addClaimText, page, text, dots, simple, preview = null, previewData = null) {
  const body = [];
  const title = spec.title.toLowerCase();
  for (let el of spec.elements) el.used = false;

  addHeader(body, spec.title, true, docx.AlignmentType.CENTER, 0); // Header - Title
  addHeader(body, spec.author, false, docx.AlignmentType.CENTER, 0); // Header - Name

  addHeader(body, "Field"); // Section - Field
  addPara(body, [text("This disclosure relates to the field of " + spec.field.toLowerCase() + ". More particularly, this disclosure relates to " + title.replaceAll("system", "systems").replaceAll("method", "methods").replaceAll("process", "processes") + ".")]);

  addHeader(body, "Background"); // Section - Background

  var foundProfane = new Set();
  var previewResult = null;
  function parsePrior(embodiment) {
    var final;
    var data = [text("FIG. " + embodiment.fig_no + " shows "), dots];
    if (embodiment.description) {
      var withNums = addElNums(". " + embodiment.description, preview === embodiment.id);
      data = data.concat(withNums);
    }
    final = addPara(body, data);

    if (preview === embodiment.id) {
      previewResult = final;
    }

    if (embodiment.embodiments) {
      const priors = embodiment.embodiments.filter(e => e.prior && e.fig_no);
      for (const embodiment of priors) parsePrior(embodiment);
    }
  }

  const priors = spec.embodiments.filter(e => e.prior && e.fig_no);
  if (priors.length) {
    for (const embodiment of priors) parsePrior(embodiment);
  } else {
    addPara(body, [dots]);
  }


  addPara(body, [dots, text(". What is needed, therefore, is a " + spec.title.toLowerCase() + ".")]);
  page(body);

  addHeader(body, "Brief Description of the Drawings", true, docx.AlignmentType.LEFT, 0); // Section - Figures
  addPara(body, [text(boilerplate.patent_drawings)]);

  // addPara(body, [text("FIG. 1 shows "), dots, text(" according to one embodiment of the present disclosure;")]);
  function parseFigure(embodiment) {
    var txt = "FIG. " + embodiment.fig_no + " shows ";
    if (embodiment.type === "independent") {
      txt += "a basic example of a " + title;
    } else if (embodiment.type === "dependent") {
      txt += "a " + title;
      if (embodiment.components.length) {
        txt += " further comprising ";
        for (const [index, component] of embodiment.components.entries()) {
          if (index === embodiment.components.length-2) txt += (component + ", and ");
          else if (index === embodiment.components.length-1) txt += (component);
          else txt += (component + ", ");
        }
      } else if (embodiment.condition) {
        txt += " whereby " + embodiment.condition;
      }
    }
    txt += " according to one embodiment of the present disclosure";
    if (embodiment.fig_no === spec.figs) txt += ".";
    else if (embodiment.fig_no === spec.figs - 1) txt += "; and";
    else txt += ";";
    addPara(body, [text(txt + " ")]);
    if (embodiment.embodiments) {
      const figs = embodiment.embodiments.filter(e => e.fig_no);
      for (const fig of figs) parseFigure(fig);
    }
  }

  const figs = spec.embodiments.filter(e => e.fig_no);
  if (figs.length) {
    for (const fig of figs) parseFigure(fig);
  } else {
    addPara(body, [text("FIG. 1 shows "), dots, text(" according to one embodiment of the present disclosure;")]);
  }
  page(body);

  addHeader(body, "Detailed Description", true, docx.AlignmentType.LEFT, 0); // Section - Detailed Description
  addPara(body, [text(boilerplate.patent_definitions)]);
  addPara(body, [text("Embodiments of a " + title + " described herein may be implemented using various components such as one or more computers, computer readable storage mediums, and computer networks for storing and transmitting data as described in greater detail below. The " + title + " is operable across multiple components using network connectivity, servers, databases, and devices such as smartphones or personal computers to receive and transmit data between components.")]);

  function addElNums(txt, profane = false) {
    const endings = ['.', ',', ';', ' ', '!', '?', ':', ')', ']', '"'].map(el => escapeRegExp(el)).join("|");
    var splitOn = spec.elements.map(el => escapeRegExp(el.name));
    if (profane) splitOn = splitOn.concat(profaneWords)
    splitOn = splitOn.join("|");
    const regex = new RegExp(`(${splitOn})(${endings})`)
    const split = txt.split(regex);

    return split.flatMap(word => {
      const match = spec.elements.find(el => el.name === word);
      if (profane && profaneWords.includes(word)) {
        foundProfane.add(word)
        return [text(word, false, true)]
      } else if (match) {
        let result = [text(word + " ")];
        if (match.used) result.push(text(match.number))
        else result.push(text(match.number, true))
        match.used = true;
        return result;
      } else {
        return [text(word)]
      }
    });
  }

  // addPara(body, [text("FIG. 1 shows a basic embodiment of a " + title + " consisting of "), dots]);
  // addPara(body, [text("In one embodiment, shown in FIG. 2, "), dots]);
  // addPara(body, [dots]);
  var first = true;
  function parseEmbodiment(embodiment) {
    if (embodiment.id === preview) embodiment = previewData;
    var txt = "";
    if (embodiment.type === "independent") {
      if (first) {
        if (embodiment.fig_no) txt += "FIG. " + embodiment.fig_no + " shows a basic embodiment of a " + title + " consisting of ";
        else txt += "A basic embodiment of a " + title + " consists of ";
      } else {
        if (embodiment.fig_no) txt += "FIG. " + embodiment.fig_no + " shows another basic embodiment of a " + title + " consisting of ";
        else txt += "Another basic embodiment of a " + title + " consists of ";
      }
      for (const [index, component] of embodiment.components.entries()) {
        if (index === embodiment.components.length-2) txt += (component + ", and ");
        else if (index === embodiment.components.length-1 && !embodiment.condition) txt += (component + ".");
        else if (index === embodiment.components.length-1 && embodiment.condition) txt += (component + ", whereby ");
        else txt += (component + ", ");
      }
      if (embodiment.condition) txt += embodiment.condition + '.';
      first = false;
    } else if (embodiment.type === "dependent") {
      txt += "In one embodiment, ";
      if (embodiment.fig_no) txt += "shown in FIG. " + embodiment.fig_no + ", ";
      if (embodiment.components.length) {
        txt += "a " + title + " further includes ";
        for (const [index, component] of embodiment.components.entries()) {
          if (index === embodiment.components.length-2) txt += (component + ", and ");
          else if (index === embodiment.components.length-1 && embodiment.condition) txt += (component + ", whereby ");
          else if (index === embodiment.components.length-1) txt += (component + ".");
          else txt += (component + ", ");
        }
      }
      if (embodiment.condition) txt += embodiment.condition + ".";
    }

    var final = null;

    txt += " ";
    if (embodiment.description) txt += embodiment.description;

    var withNums = addElNums(txt, preview === embodiment.id)

    if (!embodiment.description) withNums.push(dots)

    final = addPara(body, withNums);

    if (preview === embodiment.id) {
      previewResult = final;
    }

    if (embodiment.embodiments) {
      for (const child of embodiment.embodiments.filter(e => !e.prior)) parseEmbodiment(child);
    }
  }

  for (const embodiment of spec.embodiments.filter(e => !e.prior)) parseEmbodiment(embodiment);

  if (preview) return {previewResult, foundProfane};

  addPara(body, [text("The " + title + " described herein advantageously "), dots]);
  addPara(body, [text(boilerplate.patent_embodiments)]);
  page(body);

  // Section - Claims

  var claimNo = 0;
  var firstIndClaim;

  function parseClaim(claim, parent) {
    claimNo++;
    if (claim.type === "independent") {
      if (!firstIndClaim) firstIndClaim = claim;
      addClaim(body, [text("A " + title + " comprising:")]);
      for (const [index, component] of claim.components.entries()) {
        if (index === claim.components.length-2) addClaimText(body, component + "; and");
        else if (index === claim.components.length-1 && !claim.condition) addClaimText(body, component + ".");
        else addClaimText(body, component + ";");
      }
      if (claim.condition) addClaimText(body, 'whereby ' + claim.condition + '.');
    } else if (claim.type === "dependent") {
      var txt = "The " + title + " of claim " + parent;
      if (claim.components.length) {
        txt += ", further comprising ";
        for (const [index, component] of claim.components.entries()) {
          if (index === claim.components.length-2) txt += (component + ", and ");
          else if (index === claim.components.length-1 && claim.condition) txt += (component);
          else if (index === claim.components.length-1) txt += (component + ".");
          else txt += (component + ", ");
        }
      }
      if (claim.condition) txt += ', wherein ' + claim.condition;
      txt += '.';
      addClaim(body, [text(txt)]);
    }
    if (claim.embodiments) {
      const claims = claim.embodiments.filter(e => e.claim);
      for (const claim of claims) parseClaim(claim, claimNo);
    }
  }

  simple(body, [text("A " + title + " is further characterized as follows:")]);

  const claims = spec.embodiments.filter(e => e.claim);
  if (claims.length) {
    for (const claim of claims) parseClaim(claim);
  } else {
    addClaim(body, [text("A " + title + " comprising: "), dots]);
    addClaim(body, [text("The " + title + " of claim 1, whereby "), dots]);
  }
  page(body);

  addHeader(body, "\tAbstract", true, docx.AlignmentType.LEFT, 0); // Section - Abstract
  if (firstIndClaim) {
    var abstract = "\tA " + title + " includes: ";
    for (const [index, component] of firstIndClaim.components.entries()) {
      if (index === firstIndClaim.components.length-2) abstract += (component + "; and ");
      else if (index === firstIndClaim.components.length-1 && !firstIndClaim.condition) abstract += (component + ".");
      else abstract += (component + "; ");
    }
    if (firstIndClaim.condition) abstract += ('whereby ' + firstIndClaim.condition + '.');
    simple(body, [text(abstract)]);
  } else {
    simple(body, [text("\tA " + title + " includes: "), dots]);
  }

  return body;
}

export default forge;
