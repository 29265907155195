import React from 'react';
import { useParams } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Navbar from "../components/Navbar";
import SortableTree, {removeNodeAtPath} from 'react-sortable-tree-patch-react-17';
import forgeHTML from '../forge/html';
import forgeDOCX from '../forge/docx';
import figsDOCX from '../forge/figs';
import { v4 as uuidv4 } from 'uuid';
import {createObject, readObject, writeObject} from '../tools/data';

function save(filename, data) {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
}

const Wrapper = (props) => {
  const params = useParams();
  return <Doc {...{...props, match: {params}} } />
}

function embodiment2text(embodiment) {
  var txt = "… ";
  if (embodiment.type === "independent") {
    txt += "consists of ";
    for (const [index, component] of embodiment.components.entries()) {
      if (index === embodiment.components.length-2) txt += (component + ", and ");
      else if (index === embodiment.components.length-1 && !embodiment.condition) txt += (component + ".");
      else if (index === embodiment.components.length-1 && embodiment.condition) txt += (component + ", whereby ");
      else txt += (component + ", ");
    }
    if (embodiment.condition) txt += embodiment.condition + '.';
  } else if (embodiment.type === "dependent") {
    if (embodiment.components.length) {
      txt += " further includes ";
      for (const [index, component] of embodiment.components.entries()) {
        if (index === embodiment.components.length-2) txt += (component + ", and ");
        else if (index === embodiment.components.length-1 && embodiment.condition) txt += (component + ", ");
        else if (index === embodiment.components.length-1) txt += (component + ".");
        else txt += (component + ", ");
      }
    }
    if (embodiment.condition) txt += "whereby " + embodiment.condition + ".";
  }
  if (!(embodiment.components.length || embodiment.condition)) txt = "In one embodiment, …";
  return txt;
}

class EmbodimentTree extends React.Component {
  constructor(props) {
    super(props);
    this.map = [];
    this.onChange = this.onChange.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.removeFigure = this.removeFigure.bind(this);
    this.makePreview = this.makePreview.bind(this);
    this.query = this.query.bind(this);
    this.state = {modal: null, query: ''}

    this.components = React.createRef();
    this.condition = React.createRef();
    this.figure = React.createRef();
    this.claim = React.createRef();
    this.prior = React.createRef();
    this.description = React.createRef();
    this.claimNo = 0;
    this.figNo = 0;
  }

  query(e) {
    this.setState({query: e.target.value});
  }

  removeFigure(treeData) {
    return (e) => {
      e.preventDefault()
      this.map[this.state.editing].figure = null;
      this.onChange(treeData);
    }
  }

  makePreview() {
    const preview = this.state.preview;
    preview.claim = this.claim.current.checked;
    preview.prior = this.prior.current.checked;
    preview.condition = this.condition.current.value.trim();
    preview.description = this.description.current.value.trim();
    preview.components = this.components.current.value ? this.components.current.value.split(/\r?\n/).map(c => c.trim()).filter(c => c) : [];
    this.setState({preview: preview});
  }

  saveChanges(treeData) {
    return (e) => {
      e.preventDefault()
      this.map[this.state.editing].claim = this.claim.current.checked;
      this.map[this.state.editing].prior = this.prior.current.checked;
      this.map[this.state.editing].condition = this.condition.current.value.trim();
      this.map[this.state.editing].description = this.description.current.value.trim();
      this.map[this.state.editing].components = this.components.current.value ? this.components.current.value.split(/\r?\n/).map(c => c.trim()).filter(c => c) : [];

      if (this.figure.current && this.figure.current.files && this.figure.current.files.length) {
        var reader = new FileReader();
        reader.readAsDataURL(this.figure.current.files[0]);
        reader.onload = () => {
          const id = uuidv4();
          createObject('img#' + id, reader.result);
          this.map[this.state.editing].figure = id;
          this.onChange(treeData);
          this.closeModal();
        };
      } else {
        this.onChange(treeData);
        this.closeModal();
      }
    }
  }

  closeModal() {
    this.setState({modal: false})
  }

  expandAll() {
    const filter = (e) => {
      e.expanded = true;
      e.embodiments = e.embodiments.map(e => filter(e))
      return e;
    }

    const embodiments = this.props.embodiments.map(e => filter(e))
    this.props.callback(embodiments)
  }

  collapseAll() {
    const filter = (e) => {
      e.expanded = false;
      e.embodiments = e.embodiments.map(e => filter(e))
      return e;
    }

    const embodiments = this.props.embodiments.map(e => filter(e))
    this.props.callback(embodiments)
  }

  onChange(treeData) {
    var figs = 0;
    const render = (e, type) => {
      var emb = this.map[e.id];
      emb.type = type;
      emb.expanded = e.expanded;
      if (emb.figure) {
        figs++;
        emb["fig_no"] = figs;
      } else {
        emb["fig_no"] = null;
      }
      emb.embodiments = e.children.map(e => render(e, 'dependent'))
      return emb;
    }

    const embodiments = treeData.map(e => render(e, 'independent'))
    this.props.callback(embodiments, figs)
  }

  render() {
    const parse = (e) => {
      this.map[e.id] = e;
      var subtitle = [];
      if (e.prior) {
        subtitle.push("PRIOR ART");
      }
      if (e.claim) {
        this.claimNo++;
        subtitle.push("CLAIM " + this.claimNo);
      }
      if (e.fig_no) {
        subtitle.push("FIG. " + e.fig_no);
      }
      if (e.description) {
        subtitle.push("Description: " + e.description);
      }
      return {
        title: embodiment2text(e),
        subtitle: subtitle.join("  •  "),
        id: e.id,
        expanded: e.expanded,
        children: e.embodiments.map(e => parse(e))
      }
    }

    this.map = [];
    this.claimNo = 0;
    this.figNo = 0;
    const treeData = this.props.embodiments.map(e => parse(e));
    const getNodeKey = ({ treeIndex }) => treeIndex;

    var preview = null;
    var profanity = null;

    if (this.state.modal === "edit") {
      const result = forgeHTML(this.props.doc, this.state.editing, this.state.preview)
      preview = result.previewResult
      profanity = result.foundProfane
    }

    return (<>
      <div className="row">
        <div className="col-6">
          <div className="input-group">
            <span className="input-group-text"><i className="fa fa-search" /></span>
            <input onChange={this.query} type="text" className="form-control" placeholder="Search embodiments…" />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-4">
              <button onClick={this.expandAll} className="btn btn-secondary w-100"><i className="fa fa-expand-alt" />&nbsp; Expand All</button>
            </div>
            <div className="col-4">
              <button onClick={this.collapseAll} className="btn btn-secondary w-100"><i className="fa fa-compress-alt" />&nbsp; Collapse All</button>
            </div>
            <div className="col-4">
              <button onClick={this.props.addEmbodiment} className="btn btn-primary w-100"><i className="fa fa-plus-circle" />&nbsp; Add Embodiment</button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="tree-view">
        <SortableTree
          treeData={treeData}
          onChange={this.onChange}
          searchQuery={this.state.query}
          generateNodeProps={({ node, path }) => ({
            buttons: [
              <button
                className="btn btn-secondary btn-sm me-1"
                onClick={() => {
                  this.setState({modal: 'edit', editing: node.id, preview: this.map[node.id]})
                }}
              ><i className="fa fa-edit" /></button>,
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  this.confirm = () => {
                    this.onChange(removeNodeAtPath({
                      treeData,
                      path,
                      getNodeKey,
                    }))
                    this.setState({modal: null})
                  }
                  this.setState({modal: 'delete', deleteText: node.title})
                }}
              ><i className="fa fa-trash" /></button>
            ],
          })}
        />
      </div>
      <Modal show={this.state.modal === "delete"} onHide={this.closeModal}>
        <div className="modal-header">
          <h5 className="modal-title">Delete Embodiment?</h5>
          <button onClick={this.closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        <form onSubmit={this.create}>
          <div className="modal-body py-0">
            <p>Are you sure you want to delete this embodiment and all of its dependents? This action cannot be undone!</p>
            <p className="text-muted">{this.state.deleteText}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={this.confirm}>Delete</button>
            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
          </div>
        </form>
      </Modal>
      <Modal show={this.state.modal === "edit"} onHide={this.closeModal} className="modal-wide">
        <div className="modal-header">
          <h5 className="modal-title">Edit Embodiment</h5>
          <button onClick={this.closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        <form onSubmit={this.saveChanges(treeData)}>
          {this.state.modal === "edit" &&
          <div className="modal-body">
            <div className="row">
              <div className="col-6">
                <label htmlFor="components" className="form-label">Components (one per line, optional)</label>
                <textarea onChange={this.makePreview} defaultValue={this.map[this.state.editing].components.join("\n")} ref={this.components} id="components" rows="4" type="text" className="form-control mb-4" placeholder={"eg. a database server\na file server\na web server"} />
                <label htmlFor="condition" className="form-label">Condition (optional)</label>
                <div className="input-group mb-4">
                  <div className="input-group-text">Whereby…</div>
                  <input onChange={this.makePreview} defaultValue={this.map[this.state.editing].condition} ref={this.condition} id="condition" type="text" className="form-control" placeholder="eg. the file server further uses AES encryption" />
                </div>
                <div className="form-group mb-4">
                  {this.map[this.state.editing].figure
                    ? <>
                      <div className="row">
                        <div className="col-7">
                          <label htmlFor="formFile" className="form-label">Figure (optional)</label>
                          <p className="text-muted">You've already uploaded a figure. Remove the current figure if you wish to upload a new one.</p>
                          <button onClick={this.removeFigure(treeData)} type="button" className="btn btn-danger btn-sm"><i className="fa fa-trash" />&nbsp; Remove Figure</button>
                        </div>
                        <div className="col-5">
                          <div className="fig-display">
                            <img className="img-fluid rounded" src={readObject("img#" + this.map[this.state.editing].figure)} alt="figure" />
                          </div>
                        </div>
                      </div>
                    </>
                    : <>
                      <label htmlFor="formFile" className="form-label">Figure (optional)</label>
                      <input ref={this.figure} className="form-control" type="file" id="formFile" />
                    </>
                  }
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="flexCheckBackground" className="form-label">Background (optional)</label>
                  <div className="form-check">
                    <input onChange={this.makePreview} defaultChecked={this.map[this.state.editing].prior} ref={this.prior} className="form-check-input" type="checkbox" value="" id="flexCheckBackground" />
                    <label className="form-check-label" htmlFor="flexCheckBackground">Include this paragraph in the background (prior art) section</label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="flexCheckDefault" className="form-label">Claim (optional)</label>
                  <div className="form-check">
                    <input onChange={this.makePreview} defaultChecked={this.map[this.state.editing].claim} ref={this.claim} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">Include a claim corresponding to this embodiment</label>
                  </div>
                </div>
                {(this.state.preview.claim && this.state.preview.prior) ? <div style={{fontSize: '14px'}} className="alert alert-warning mt-3 mb-0">
                  <i className="fa fa-info-circle" />&nbsp; It is unusual for a background paragraph to have a claim.
                </div> : <></>}
              </div>
              <div className="col-6">
                <label htmlFor="description" className="form-label">Description (optional)</label>
                <textarea onChange={this.makePreview} defaultValue={this.map[this.state.editing].description} ref={this.description} id="description" rows="5" type="text" className="form-control mb-4" placeholder="eg. In a first step, a user submits a credential to an authentication server. In a second step the user receives an authentication token from the authentication server. In a third step …" />
                <div className="bg-light p-3 py-4">
                  <p className="preview-text mt-0 mb-2">Preview</p>
                  <div className="page-preview singleton">
                    {preview}
                  </div>
                  {(profanity && profanity.size > 0) ? <div style={{fontSize: '14px'}} className="alert alert-dismissible alert-warning mt-4 mb-0">
                    <i className="fa fa-info-circle" />&nbsp; The following word(s) may be too restrictive: {[...profanity].join(', ')}
                  </div> : <></>}
                </div>
              </div>
            </div>
          </div>
          }
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">Save changes</button>
            <button type="button" className="btn btn-secondary" onClick={this.closeModal}>Cancel</button>
          </div>
        </form>
      </Modal>
    </>);
  }
}


class Doc extends React.Component {
  constructor(props) {
    super(props)
    this.id = this.props.match.params.id;
    const doc = readObject('doc#' + this.id);
    this.state = {doc: doc, modal: false}
    this.download = this.download.bind(this)
    this.figs = this.figs.bind(this)
    this.spec = this.spec.bind(this)
    this.addEmbodiment = this.addEmbodiment.bind(this)
    this.newEmbodiments = this.newEmbodiments.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.dictionary = this.dictionary.bind(this)
    this.details = this.details.bind(this)
  }

  dictionary(dictionary) {
    const elements = [];
    function depthOf(object) {
      if (object.children && object.children.length) {
        const depths = object.children.map(c => depthOf(c))
        return Math.max(...depths) + 1;
      } else {
        return 1;
      }
    }
    const depths = dictionary.map(c => depthOf(c));
    const depth = Math.max(Math.max(...depths), 3);
    const sizes = new Array(depth).fill(1);
    function sizeFill(array, depth) {
      sizes[depth] = Math.max(sizes[depth], array.length.toString().length);
      for (const c of array) {
        if (c.children && c.children.length) sizeFill(c.children, depth + 1)
      }
    }
    sizeFill(dictionary, 0);
    function number(array, path, depth) {
      for (const [i, c] of array.entries()) {
        const numbers = [...path];
        numbers[depth] = i + 1;
        const figNo = numbers.map((e,i) => e.toString().padStart(sizes[i], '0')).join('');
        c.subtitle = figNo
        elements.push({name: c.title, number: figNo});
        if (c.children && c.children.length) number(c.children, numbers, depth + 1)
      }
    }
    number(dictionary, new Array(depth).fill(0), 0)

    var doc = this.state.doc;
    doc.dictionary = dictionary;
    doc.elements = elements;
    doc.edited = Date.now();
    this.setState({doc: doc});
  }

  details(title, inventor, field) {
    var doc = this.state.doc;
    doc.title = title;
    doc.author = inventor;
    doc.field = field;
    doc.edited = Date.now();
    this.closeModal();
    this.setState({doc: doc});
  }

  closeModal() {
    this.setState({modal: false})
  }

  async spec() {
    const spec = JSON.stringify(this.state.doc);
    const date = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll(' ', '-').replaceAll(':','-');
    const name = this.state.doc.title.toLowerCase().replaceAll(' ', '-') + '-spec-' + date + '.json';
    save(name, spec);
  }

  async download() {
    const docx = await forgeDOCX(this.state.doc);
    const date = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll(' ', '-').replaceAll(':','-');
    const name = this.state.doc.title.toLowerCase().replaceAll(' ', '-') + '-' + date + '.docx';
    save(name, docx);
  }

  async figs() {
    const docx = await figsDOCX(this.state.doc);
    const date = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll(' ', '-').replaceAll(':','-');
    const name = this.state.doc.title.toLowerCase().replaceAll(' ', '-') + '-figs-' + date + '.docx';
    save(name, docx);
  }

  newEmbodiments(embodiments, figs) {
    var doc = this.state.doc;
    doc.embodiments = embodiments;
    doc.figs = figs;
    doc.edited = Date.now();
    this.setState({doc: doc});
  }

  addEmbodiment() {
    var doc = this.state.doc;
    doc.embodiments.push({
      "type": "independent",
      "components": [],
      "description": "",
      "condition": "",
      "claim": false,
      "prior": false,
      "expanded": true,
      "fig_no": null,
      "id": uuidv4(),
      "embodiments": []
    })
    doc.edited = Date.now();
    this.setState({doc: doc});
  }

  render() {
    writeObject('doc#' + this.id, this.state.doc);
    return (<>
      <Navbar />
      <div className="bg-light page-bg-half px-4 py-5">
        <p className="preview-text mb-2">Preview</p>
        <div className="page-preview">
          {forgeHTML(this.state.doc)}
        </div>
        <div className="row mt-5">
          <div className="col-12 mb-3">
            <div className="d-grid gap-2">
              <button className="btn btn-primary" type="button" onClick={this.download}><i className="fa fa-file-word"></i>&nbsp; Download Disclosure (.docx)</button>
            </div>
          </div>
          <div className="col-6">
            <div className="d-grid gap-2">
              <button className="btn btn-secondary" type="button" onClick={this.figs}><i className="fa fa-file-word"></i>&nbsp; Download Figures (.docx)</button>
            </div>
          </div>
          <div className="col-6">
            <div className="d-grid gap-2">
              <button className="btn btn-secondary" type="button" onClick={this.spec}><i className="fa fa-file-code"></i>&nbsp; Download Specification (.json)</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid doc-editor p-5">
        <div className="row">
          <div className="col-9">
            <h2 className="mt-0 no-overflow">{this.state.doc.title}</h2>
            <h4 className="text-muted no-overflow">Inventor: {this.state.doc.author} &nbsp;&middot;&nbsp; Field: {this.state.doc.field}</h4>
          </div>
          <div className="col-3">
            <button className="btn btn-primary w-100 mb-2" type="button" onClick={() => this.setState({modal: 'edit-dictionary'})}><i className="fa fa-book"></i>&nbsp; Element Dictionary</button>
            <button className="btn btn-secondary btn-sm w-100" type="button" onClick={() => this.setState({modal: 'edit-details'})}><i className="fa fa-edit"></i>&nbsp; Patent Details</button>
          </div>
        </div>
        <h3 className="mt-5">Embodiments</h3>
        <EmbodimentTree embodiments={this.state.doc.embodiments} doc={this.state.doc} callback={this.newEmbodiments} addEmbodiment={this.addEmbodiment} />
      </div>
      <EditDetails show={this.state.modal === "edit-details"} hide={this.closeModal} doc={this.state.doc} callback={this.details} />
      <EditDictionary show={this.state.modal === "edit-dictionary"} hide={this.closeModal} doc={this.state.doc} callback={this.dictionary} />
    </>);
  }
}

class EditDictionary extends React.Component {
  constructor(props) {
    super(props)
    this.update = this.update.bind(this);
    this.addTerm = this.addTerm.bind(this);
    this.term = React.createRef();
  }

  update(e) {
    e.preventDefault()
  }

  add(name) {
    return (e) => {
      var dictionary = this.props.doc.dictionary;
      dictionary.push({title: name.trim(), children: []});
      this.props.callback(dictionary);
    }
  }

  addTerm(e) {
    e.preventDefault();
    if (this.term.current.value.trim()) {
      var dictionary = this.props.doc.dictionary;
      dictionary.push({title: this.term.current.value.trim(), children: []});
      this.props.callback(dictionary);
      this.term.current.value = '';
    }
  }

  render() {
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const treeData = this.props.doc.dictionary;

    var components = [];

    function findComponents(e) {
      if (e.components) components.push(e.components);
      if (e.embodiments) for (const c of e.embodiments) findComponents(c);
    }

    for (const e of this.props.doc.embodiments) findComponents(e);

    components = [...new Set(components.flat().map(s => s.replace(/^(a |the |an )/gi, '')))];
    const dict = new Set(this.props.doc.elements.map(s => s.name.replace(/^(a |the |an )/gi, '')));

    const notFound = components.filter(c => !dict.has(c))

    return (
      <Modal show={this.props.show} onHide={this.props.hide} className="modal-wide">
        <div className="modal-header">
          <h5 className="modal-title">Edit Dictionary of Elements</h5>
          <button onClick={this.props.hide} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className="modal-body">
          <form className="row" onSubmit={this.addTerm}>
            <div className="col-9">
              <input type="text" className="form-control" ref={this.term} placeholder="Element Name (eg. Helical Gear)" />
            </div>
            <div className="col-3">
              <button type="submit" className="btn btn-primary w-100 mb-3">Add Element</button>
            </div>
          </form>
          <div style={{ height: 500 }}>
            {!treeData.length && <div className="zero-state"><p>There are no named elements yet. Get started by adding an element to the dictionary.</p></div>}
            <SortableTree
              treeData={treeData}
              onChange={treeData => this.props.callback(treeData)}
              generateNodeProps={({ node, path }) => ({
                buttons: [
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      this.props.callback(removeNodeAtPath({
                        treeData,
                        path,
                        getNodeKey
                      }))
                    }}
                  ><i className="fa fa-trash" /></button>
                ],
              })}
            />
          </div>
          {(notFound && notFound.length) ? <div style={{fontSize: '14px'}} className="alert alert-warning mt-3 mb-0">
            <i className="fa fa-info-circle" />&nbsp; Some terms(s) from your embodiments that might be missing: {notFound.map((el, i) => {
              const ct = <button onClick={this.add(el)} className="btn btn-link m-0 p-0">{el}</button>
              if (i < notFound.length - 1) return <React.Fragment key={el}>{ct}, </React.Fragment>;
              else return <React.Fragment key={el}>{ct}</React.Fragment>;
            })}.
          </div> : <></>}
        </div>
      </Modal>
    )
  }
}

class EditDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.doc.title,
      field: this.props.doc.field,
      inventor: this.props.doc.author
    }
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onInventorChange = this.onInventorChange.bind(this);
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
  }

  onTitleChange(e) {
    this.setState({title: e.target.value})
  }

  onFieldChange(e) {
    this.setState({field: e.target.value})
  }

  onInventorChange(e) {
    this.setState({inventor: e.target.value})
  }

  update(e) {
    e.preventDefault()
    if (this.state.title && this.state.inventor && this.state.field) {
      this.props.callback(this.state.title, this.state.inventor, this.state.field)
    }
  }

  hide() {
    this.props.hide();
    this.setState({
      title: this.props.doc.title,
      field: this.props.doc.field,
      inventor: this.props.doc.author
    })
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.hide} className="modal-wide">
        <div className="modal-header">
          <h5 className="modal-title">Edit Patent Details</h5>
          <button onClick={this.hide} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
        </div>
        <form onSubmit={this.update}>
          <div className="modal-body py-0">
            <div className="row">
              <div className="col-6 py-3">
                <div className="form-group">
                  <label htmlFor="provisional-patent-title" className="form-label">Title</label>
                  <input onChange={this.onTitleChange} type="text" className="form-control" id="provisional-patent-title" placeholder="eg. System and Method for Multi-Factor Authentication" defaultValue={this.state.title} />
                  <label htmlFor="provisional-patent-inventor" className="form-label mt-4">Inventor</label>
                  <input onChange={this.onInventorChange} type="text" className="form-control" id="provisional-patent-inventor" placeholder="eg. James Smith" defaultValue={this.state.inventor} />
                  <label htmlFor="provisional-patent-field" className="form-label mt-4">Field</label>
                  <input onChange={this.onFieldChange} type="text" className="form-control" id="provisional-patent-field" placeholder="eg. Information Security" defaultValue={this.state.field} />
                </div>
              </div>
              <div className="col-6 bg-light py-3">
                <p className="preview-text mt-0 mb-2">Preview</p>
                <div className="page-preview">
                  <h1 className="text-center"><b>{this.state.title ? this.state.title : <span className="text-muted">System and Method for Multi-Factor Authentication</span>}</b></h1>
                  <h1 className="text-center">{this.state.inventor ? this.state.inventor : <span className="text-muted">James Smith</span>}</h1>
                  <div className={this.state.field ? '' : 'text-muted'}>
                    <h1><b>Field</b></h1>
                    <p className="mb-0"><b>[0001]</b> This disclosure relates to the field of {this.state.field ? this.state.field.toLowerCase() : 'information security'}. More particularly, this disclosure relates to {this.state.title ? this.state.title.toLowerCase().replaceAll("system", "systems").replaceAll("method", "methods").replaceAll("process", "processes") : 'systems and methods for multi-factor authentication'}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary" disabled={!(this.state.title && this.state.inventor && this.state.field)}>Save Changes</button>
            <button type="button" className="btn btn-secondary" onClick={this.hide}>Cancel</button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default Wrapper;
