import patent from './patent';
import React from 'react';

var pnum = 0;
var cnum = 0;
var snum = 0;
var bnum = 0;
var tnum = 0;
var hnum = 0;
var txtnum = 0;

function addHeader(body, text, bold = true, alignment = "left", nl = 1) {
  hnum++;
  var classes = [];
  if (alignment !== "left") classes.push("text-center");
  if (bold) classes.push("fw-bold");
  body.push(<h1 key={hnum + 'header-' + text.toLowerCase()} className={classes.join(' ')}>{text}</h1>);
}

function addPara(body, children) {
  pnum++;
  const para = <p key={'para-' + pnum}><b>[{pnum.toString().padStart(4, '0')}]</b> {children}</p>;
  body.push(para);
  return para;
}

function addClaim(body, children) {
  cnum++;
  body.push(<div className="row" key={'claim-' + cnum}>
    <div className="col-1">
      <p><b>{cnum}.</b></p>
    </div>
    <div className="col-11">
      <p>{children}</p>
    </div>
  </div>);
}

function addClaimText(body, text) {
  tnum++;
  body.push(<div className="row" key={'ct-' + tnum}>
    <div className="col-11 offset-1">
      <p>{text}</p>
    </div>
  </div>);
}

function page(body) {
  bnum++;
  body.push(<hr key={'br-' + bnum} />)
}

function text(txt, bold = false, highlight = false) {
  txtnum++;
  if (highlight) return <mark key={txt + txtnum}>{txt}</mark>;
  if (bold) return <b key={txt + txtnum}>{txt}</b>;
  return <React.Fragment key={txt + txtnum}>{txt}</React.Fragment>;
}

function simple(body, txt) {
  snum++;
  body.push(<p key={'simple-' + snum}>{txt}</p>);
}

function forge(spec, preview = null, previewData = null) {
  pnum = 0;
  cnum = 0;
  snum = 0;
  bnum = 0;
  tnum = 0;
  hnum = 0;
  txtnum = 0;
  const dots = <mark key="dots">…</mark>;
  return patent(spec, addHeader, addPara, addClaim, addClaimText, page, text, dots, simple, preview, previewData);
}

export default forge
