import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import 'react-sortable-tree-patch-react-17/style.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Home from './pages/Home';
import Doc from './pages/Doc';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/doc/:id" element={<Doc />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
