const docx = require("docx");

async function getImageSize(img) {
  return new Promise((resolve, reject) => {
    const i = new Image();
    i.onload = function() {
      resolve(i);
    };
    i.src = img;
  });
}

async function figs(spec) {
  const body = [];
  var first = true;
  async function parseFigure(e) {
    if (!first) body.push(new docx.Paragraph({children: [new docx.PageBreak()]}));
    first = false;
    body.push(new docx.Paragraph({children: [new docx.TextRun({text: "FIG. " + e.fig_no, bold: true, size: 48})]}));
    if (e.prior) body.push(new docx.Paragraph({children: [new docx.TextRun({text: "(Prior Art)", size: 36})]}));
    body.push(new docx.Paragraph({children: [new docx.TextRun({text: "", size: 36, break: 1})]}));

    const imageData = localStorage.getItem("img#" + e.figure);
    const imageSize = await getImageSize(imageData);
    var width = 6.5 * 96;
    var height = 6.5 * 96;
    if (imageSize.width > imageSize.height) height = width * (imageSize.height / imageSize.width);
    else width = height * (imageSize.width / imageSize.height);

    const image = new docx.ImageRun({
      data: imageData,
      transformation: {
        width: width,
        height: height,
      }
    });
    body.push(new docx.Paragraph({children: [image], alignment: docx.AlignmentType.CENTER}));
    if (e.embodiments) {
      const figs = e.embodiments.filter(e => e.fig_no);
      for (const fig of figs) await parseFigure(fig);
    }
  }

  const figs = spec.embodiments.filter(e => e.fig_no);
  if (figs.length) {
    for (const fig of figs) await parseFigure(fig);
  }

  const doc = new docx.Document({
    creator: "DraftForge",
    title: spec.title,
    sections: [{
      properties: {
        page: {
          size: {
            height: 15840,
            width: 12240
          }
        }
      },
      children: body
    }],
  });

  return await docx.Packer.toBuffer(doc);;
}

export default figs
