/*
 * NOTE: Some components rely on these being synchronous.
 * Update those components before changing this to async.
 */

function createObject(name, data) {
  localStorage.setItem(name, typeof data === 'object' ? JSON.stringify(data) : data);
}

function deleteObject(name) {
  localStorage.removeItem(name);
}

function readObject(name) {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (err) {
    return localStorage.getItem(name);
  }
}

function writeObject(name, data) {
  localStorage.setItem(name, typeof data === 'object' ? JSON.stringify(data) : data);
}

function listObjects(prefix = '') {
  var list = [];
  for (var key in localStorage) {
    if (key.startsWith(prefix)) {
      list.push(readObject(key))
    }
  }
  return list;
}

export {createObject, deleteObject, readObject, writeObject, listObjects}
