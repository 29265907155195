import patent from './patent';
const docx = require("docx");

function addHeader(body, text, bold = true, alignment = docx.AlignmentType.LEFT, nl = 1) {
  body.push(new docx.Paragraph({
    children: [new docx.TextRun({text: text, bold: bold, break: nl})],
    heading: docx.HeadingLevel.HEADING_1,
    alignment: alignment
  }));
}

function addPara(body, children) {
  body.push(new docx.Paragraph({
    children: children,
    heading: docx.HeadingLevel.HEADING_2,
    alignment: docx.AlignmentType.JUSTIFIED,
    numbering: {
      reference: "para-numbers",
      level: 0
    }
  }))
}

function addClaim(body, children) {
  body.push(new docx.Paragraph({
    children: children,
    heading: docx.HeadingLevel.HEADING_3,
    alignment: docx.AlignmentType.JUSTIFIED,
    numbering: {
      reference: "claim-numbers",
      level: 0
    },
    indent: {
      start: docx.convertInchesToTwip(0.5),
      hanging: docx.convertInchesToTwip(0.5)
    }
  }))
}

function addClaimText(body, text) {
  body.push(new docx.Paragraph({
    children: [new docx.TextRun({text: text})],
    heading: docx.HeadingLevel.HEADING_3,
    alignment: docx.AlignmentType.JUSTIFIED,
    indent: {
      hanging: docx.convertInchesToTwip(0.5),
      left: docx.convertInchesToTwip(1.0)
    }
  }))
}

function page(body) {
  body.push(new docx.Paragraph({children: [new docx.PageBreak()]}));
}

function text(txt, bold = false) {
  return new docx.TextRun({text: txt, bold: bold});
}

function simple(body, txt) {
  body.push(new docx.Paragraph({
    children: txt,
    heading: docx.HeadingLevel.HEADING_2,
    alignment: docx.AlignmentType.JUSTIFIED
  }));
}

async function forge(spec) {
  const dots = new docx.TextRun({text: '…', highlight: "yellow"});
  const body = patent(spec, addHeader, addPara, addClaim, addClaimText, page, text, dots, simple);

  // Export Word Doc
  const doc = new docx.Document({
    creator: "DraftForge",
    title: spec.title,
    numbering: {
      config: [
        {
          reference: "para-numbers",
          levels: [
            {
              level: 0,
              format: "decimalZero",
              text: "[%1]",
              style: {
                run: {
                  bold: true
                }
              }
            },
          ]
        }, {
          reference: "claim-numbers",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%1."
            }
          ]
        }
      ]
    },
    styles: {
      default: {
        heading1: {
          run: {
            size: 12*2,
            smallCaps: true
          },
          paragraph: {
            spacing: {
              line: 360,
              before: 240,
              after: 0
            }
          }
        },
        heading2: {
          run: {
            size: 12*2
          },
          paragraph: {
            spacing: {
              line: 360,
              before: 240,
              after: 0
            }
          }
        },
        heading3: {
          run: {
            size: 12*2
          },
          paragraph: {
            spacing: {
              line: 480,
              before: 240,
              after: 0
            }
          }
        }
      }
    },
    sections: [{
      properties: {
        page: {
          size: {
            height: 15840,
            width: 12240
          }
        }
      },
      children: body
    }],
  });
  return await docx.Packer.toBuffer(doc);
}

export default forge
